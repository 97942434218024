<template>
    <!-- Copyright Start -->
    <div class="container-fluid copyright py-5">
        <div class="container py-5">
            <div class="row g-5">
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="text-white mb-4 pmisputih">GIS Sungai</h4>
                        <p>Project Management Information System Spatial of River</p>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-2">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="mb-4 text-white pmisputih">
                            Jumlah Pengunjung
                        </h4>
                        <a href="#" class="text-white"><i class="fas fa-angle-right me-2"></i>
                            Hari ini: {{ G_numFormat(harini) }}
                        </a>
                        <a href="#" class="text-white"><i class="fas fa-angle-right me-2"></i>
                            Bulan ini: {{ G_numFormat(bulanini) }}
                        </a>
                        <a href="#" class="text-white"><i class="fas fa-angle-right me-2"></i>
                            Tahun ini: {{ G_numFormat(tahunini) }}
                        </a>
                        <a href="#" class="text-white"><i class="fas fa-angle-right me-2"></i>
                            Total: {{ G_numFormat(totalpengunjung) }}
                        </a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-3">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="mb-4 text-white pmisputih">
                            Tautan
                        </h4>
                        <a href="https://pu.go.id/" target="_blank" class="text-white"><i
                                class="fas fa-angle-right me-2"></i> Kementerian PUPR</a>
                        <a href="https://sda.pu.go.id/" target="_blank" class="text-white"><i
                                class="fas fa-angle-right me-2"></i> Ditjen SDA Kementerian PUPR</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-4">
                    <div class="footer-item d-flex flex-column">
                        <h4 class="mb-4 text-white pmisputih">
                            Info Kontak
                        </h4>
                        <a href="#" class="text-white"><i class="fas fa-house-user me-2"></i> Balai Wilayah Sungai Kalimantan III</a>
                        <a href="#" class="text-white"><i class="fa fa-map-marker-alt me-2"></i> Jl. Yos Sudarso No.10, Telaga Biru, Kec. Banjarmasin Bar., Kota Banjarmasin, Kalimantan Selatan 70117</a>
                        <a href="#" style="font-size: 15px;" class="text-white"><i class="fas fa-envelope me-2"></i>
                            bwskal3@pu.go.id</a>
                        <a href="#" class="text-white"><i class="fas fa-phone me-2"></i> (0511) 6740580</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid copyright py-4" style="background-color: #445077 !important;border-top: solid 1px #ffffff !important;">
        <div class="container">
            <div class="row g-6 align-items-center">
                <div class="col-md-4 text-center text-md-start mb-md-0">
                    <span class="text-white">
                        <a href="#" class="merahmaroon">
                            <i class="fas fa-copyright text-light me-2"></i> WEB GIS SUNGAI
                        </a>
                        {{ tahun }} All right reserved.
                    </span>
                </div>
                <div class="col-md-12 text-center text-md-start mb-md-0 text-white">
                    Designed By <a href="https://sda.pu.go.id/balai/bwskalimantan3/" target="_blank"><span class="merahmaroon">Balai Wilayah Sungai Kalimantan III</span> </a>,
                    Contributed By
                    <a href="https://parastapa.online/" target="_blank"><span class="merahmaroon">Tommy K - Parastapa
                            Technology</span></a>
                </div>
            </div>
        </div>
    </div>
    <!-- Copyright End -->
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

export default {
    components: {
        swal
    },
    data() {
        return {
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            harini: 0,
            bulanini: 0,
            tahunini: 0,
            totalpengunjung: 0,
        };
    },
    methods: {
        async ambildata() {
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovationVisitor-GetVisitor?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.harini = Response.data.hariini;
                        this.bulanini = Response.data.bulanini;
                        this.tahunini = Response.data.tahunini;
                        this.totalpengunjung = Response.data.total;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                console.log(error);
            });
        },
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>