<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/disdag.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <center>
        <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Dokumentasi</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-justify">
                        <p>
                        <div class="col-sm-12">
                            <iframe src="" id="iframefile" style="width: 100%;height: 55vh;"></iframe>
                        </div>
                        </p>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-danger btn-sm lebar" data-dismiss="modal"><i
                                class="fas fa-window-close"></i> Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
    </center>
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                DAFTAR SUNGAI
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        Beranda
                    </a>
                </li>
                <li class="breadcrumb-item active text-warning">
                    Daerah Sungai
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">
        &nbsp;
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="row px-2">
            <div class="col-12 col-sm-6 col-md-4">
                <div class="info-box">
                    <span class="info-box-icon bg-green elevation-1"><i class="	fas fa-ruler-vertical"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">Sungai Kewenangan Pusat</span>
                        <span class="info-box-number">
                            {{ G_numFormat(KewenanganPusat) }}
                            <small>Data</small>
                        </span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <div class="info-box">
                    <span class="info-box-icon bg-pink elevation-1"><i class="fas fa-ruler-horizontal"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">Sungai Kewenangan Provinsi</span>
                        <span class="info-box-number"> {{ G_numFormat(KewenanganProvinsi) }}
                            <small>Data</small>
                        </span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <div class="info-box">
                    <span class="info-box-icon bg-purple elevation-1"><i class="fas fa-ruler-combined"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">Sungai Kewenangan Kabupaten/Kota</span>
                        <span class="info-box-number"> {{ G_numFormat(KewenanganKab) }}
                            <small>Data</small>
                        </span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <div class="info-box">
                    <span class="info-box-icon bg-primary elevation-1"><i class="fas fa-icicles"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">Jumlah Sungai</span>
                        <span class="info-box-number">
                            {{ G_numFormat(JumlahSungai) }}
                            <small>Area</small>
                        </span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <div class="info-box">
                    <span class="info-box-icon bg-info elevation-1"><i class="fas fa-map"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">Panjang Total Sungai</span>
                        <span class="info-box-number">
                            {{ G_numFormatKoma(PanjangSungai) }}
                            <small>m</small>
                        </span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
            </div>
            <div class="col-12 col-sm-6 col-md-4">
                <div class="info-box">
                    <span class="info-box-icon bg-red elevation-1"><i class="fas fa-map-marked"></i></span>
                    <div class="info-box-content">
                        <span class="info-box-text">Total Luas DAS</span>
                        <span class="info-box-number">
                            {{ G_numFormatKoma(LuasDasHa) }}
                            <small>Ha</small>
                        </span>
                    </div>
                    <!-- /.info-box-content -->
                </div>
                <!-- /.info-box -->
            </div>
        </div>
    </div>
    <div class="container-fluid bg-breadcrumbberanda px-5 wow fadeInDown">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title">Daftar Daerah Irigasi</h5>

                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse">
                        <i class="fas fa-minus"></i>
                    </button>
                </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 float-left pb-2 wow fadeInDown">
                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                            placeholder="Masukkan Kata Kunci Pencarian">
                    </div>
                    <div class="table-responsive table-wrapper px-3">
                        <table class="table m-0" id="tabeldata">
                            <thead>
                                <tr>
                                    <th style="width: 50px;">No</th>
                                    <th style="width: 100px;">Dokumentasi</th>
                                    <th style="width: 150px;">Nama Sungai</th>
                                    <th style="width: 150px;">Panjang Sungai (m)</th>
                                    <th style="width: 150px;">Luas DAS (Ha)</th>
                                    <th style="width: 150px;">Kewenangan</th>
                                    <th style="width: 200px;">Balai</th>
                                    <th style="width: 80px;">Kinerja<br>Fisik</th>
                                    <th style="width: 80px;">Kinerja<br>Fungsi</th>
                                    <th style="width: 80px;">Nilai<br>Total</th>
                                    <th style="width: 80px;">
                                        Nilai<br>Kondisi<br>Fisik<br>vs<br>Kondisi<br>Fungsi</th>
                                </tr>
                            </thead>
                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                <tbody>
                                    <tr>
                                        <td :class="datalist.class + ' text-center'">{{ urutlist + 1 }}</td>
                                        <td :class="datalist.class">
                                            <img :src="folder_foto + datalist.Foto"
                                                class="img-size-75 animation__wobble" alt="InfoBeranda Image"
                                                style="height: 100px;cursor:pointer"
                                                @click="lihatlampiran(datalist.Foto)">
                                        </td>
                                        <td :class="datalist.class">
                                            {{ datalist.Nama }}
                                        </td>
                                        <td :class="datalist.class + ' text-right'">
                                            {{ G_numFormatKoma(datalist.Panjang) }}
                                        </td>
                                        <td :class="datalist.class + ' text-right'">
                                            {{ G_numFormatKoma(datalist.LuasHa) }}
                                        </td>
                                        <td :class="datalist.class">
                                            {{ datalist.NamaKewenangan }}
                                        </td>
                                        <td :class="datalist.class">
                                            {{ datalist.NmBalai }}
                                        </td>
                                        <td :class="datalist.class"> {{ G_numFormatKoma(datalist.nilaikinerjafisik) }}
                                        </td>
                                        <td :class="datalist.class"> {{ G_numFormatKoma(datalist.nilaikinerjafungsi) }}
                                        </td>
                                        <td :class="datalist.class"> {{ G_numFormatKoma(datalist.nilaitotal) }}</td>
                                        <td :class="datalist.class"> {{ datalist.kinerja }}</td>
                                    </tr>
                                </tbody>
                            </template>
                            <tbody v-if="(datapesan == 'data kosong')">
                                <tr>
                                    <td colspan="7">Data masih kosong</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- ./card-body -->
            <div class="card-footer">

            </div>
            <!-- /.card-footer -->
        </div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            datapesan: '',
            datamaster: [],
            carikata: '',
            JumlahSungai: 0,
            PanjangSungai: 0,
            LuasDasHa: 0,
            KewenanganPusat: 0,
            KewenanganProvinsi: 0,
            KewenanganKab: 0,
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_sungai-Gambar?f=',
        };
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        lihatlampiran(filenya) {
            this.halamanloading = true;
            document.getElementById('iframefile').src = "";
            //alert(process.env.VUE_APP_URL_API + foldernya + '?f=' + filenya);
            document.getElementById('iframefile').src = this.folder_foto + filenya;
            $('#costumModaldisclaimer').modal('show');
            this.halamanloading = false;
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetSungai?random=" + random).then(
                Response => {

                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.JumlahSungai = Response.data.resume[0].JumlahSungai;
                        this.PanjangSungai = Response.data.resume[0].PanjangSungai;
                        this.LuasDasHa = Response.data.resume[0].LuasDasHa;
                        this.KewenanganPusat = Response.data.resume[0].KewenanganPusat;
                        this.KewenanganProvinsi = Response.data.resume[0].KewenanganProvinsi;
                        this.KewenanganKab = Response.data.resume[0].KewenanganKab;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.halamanloading = false;
            });
        }
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style></style>