<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-5 wow fadeInDown">
                            <h5>Monitoring & Evaluasi</h5>
                        </div>
                        <div class="col-sm-7 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Data Monitoring & Evaluasi</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <label>TMA SUNGAI</label>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="col-sm-1">
                                            <label>Tanggal</label>
                                        </div>
                                        <div class="col-sm-2">
                                            <input type="date" class="form-control form-control-sm" id="tanggalmintma"
                                                name="tanggalmintma" v-model="tanggalmintma"
                                                @change="ontanggalminChange">
                                        </div>
                                        <div class="col-sm-1">
                                            <label>s/d</label>
                                        </div>
                                        <div class="col-sm-2">
                                            <input type="date" class="form-control form-control-sm" id="tanggalmaxtma"
                                                name="tanggalmaxtma" v-model="tanggalmaxtma"
                                                @change="ontanggalmaxChange">
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-3">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-info elevation-1"><i
                                                        class="fas fas fa-braille"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Jumlah Lokasi</span>
                                                    <span class="info-box-number">
                                                        {{ G_numFormat(JumlahSTA) }}
                                                        <small>Titik</small>
                                                    </span>
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-blue elevation-1"><i
                                                        class="fas fas fa-braille"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Lokasi Terdata</span>
                                                    <span class="info-box-number">
                                                        {{ G_numFormat(jumlahtma) }}
                                                        <small>Titik</small>
                                                    </span>
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-info elevation-1"><i
                                                        class="fas fa-user"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Jumlah Petugas</span>
                                                    <span class="info-box-number">
                                                        {{ G_numFormat(jumlahpetugastma) }}
                                                        <small>Personil</small>
                                                    </span>
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-blue elevation-1"><i
                                                        class="fas fa-user"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Jumlah Petugas Aktif</span>
                                                    <span class="info-box-number">
                                                        {{ G_numFormat(JumlahPetugasAktiftma) }}
                                                        <small>Personil</small>
                                                    </span>
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-success elevation-1"><i
                                                        class="fas fa-map-marker-alt"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Titik Normal</span>
                                                    <span class="info-box-number">
                                                        {{ G_numFormat(JumlahNormal) }}
                                                        <small>STA</small>
                                                    </span>
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-yellow elevation-1"><i
                                                        class="fas fa-map-marker-alt"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Titik Siaga</span>
                                                    <span class="info-box-number">
                                                        {{ G_numFormat(JumlahSiaga) }}
                                                        <small>STA</small>
                                                    </span>
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-pink elevation-1"><i
                                                        class="fas fa-map-marker-alt"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Titik Waspada</span>
                                                    <span class="info-box-number">
                                                        {{ G_numFormat(JumlahWaspada) }}
                                                        <small>STA</small>
                                                    </span>
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="info-box">
                                                <span class="info-box-icon bg-red elevation-1"><i
                                                        class="fas fa-map-marker-alt"></i></span>
                                                <div class="info-box-content">
                                                    <span class="info-box-text">Titik Awas</span>
                                                    <span class="info-box-number">
                                                        {{ G_numFormat(JumlahAwas) }}
                                                        <small>STA</small>
                                                    </span>
                                                </div>
                                                <!-- /.info-box-content -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <label>POS DUGA AIR</label>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-0">

                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <label>POS HIDROLOGI</label>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-0">

                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <label>POS KLIMATOLOGI</label>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-0">

                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            JumlahSTA: 0,
            jumlahtma: 0,
            jumlahpetugastma: 0,
            JumlahPetugasAktiftma: 0,
            JumlahNormal: 0,
            JumlahSiaga: 0,
            JumlahWaspada: 0,
            JumlahAwas: 0,
            tanggalmaxtma: new Date(),
            tanggalmintma: new Date(),
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        ontanggalmaxChange() {
            var d = new Date(this.tanggalmintma);
            var d2 = new Date(this.tanggalmaxtma);
            if (d2 < d) {
                swal.fire('Peringatan', 'Tanggal akhir tidak boleh lebih kecil.', 'error').then(function () {
                    return false;
                });
            }
            this.pilihdata();
        },
        ontanggalminChange() {
            var d = new Date(this.tanggalmintma);
            var d2 = new Date(this.tanggalmaxtma);
            if (d2 < d) {
                swal.fire('Peringatan', 'Tanggal akhir tidak boleh lebih kecil.', 'error').then(function () {
                    return false;
                });
            }
            this.pilihdata();

        },
        ontahuntmaChange(a) {
            try {
                this.selectedtahuntma = this.tahuntma[a].tag;
            }
            catch (err) {
                return;
            }
            //alert(this.selectedtahuntma);
        },
        onbulantmaChange(a) {
            try {
                this.selectedbulantma = this.bulantma[a].tag;
            }
            catch (err) {
                return;
            }
            //alert(this.selectedbulantma);
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async ambildataReff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_reff-GetReffBulan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databulantma = [];
                        this.bulantma = [];
                        this.databulantma = Response.data.content.data;
                        this.databulantma.forEach((item) => {
                            this.bulantma.push({
                                label: item.nama,
                                code: this.databulantma.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.bulantma.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });
                        //alert(JSON.stringify(this.bulantma));
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        async pilihdata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_tma-ResumeTMAAll?random=" + random + "&tanggalawal=" + this.tanggalmintma + "&tanggalakhir=" + this.tanggalmaxtma).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.JumlahSTA = Response.data.content.data[0].JumlahSTA;
                        this.jumlahtma = Response.data.content.data[0].JumlahLokasi;
                        this.JumlahNormal = Response.data.content.data[0].JumlahNormal;
                        this.JumlahSiaga = Response.data.content.data[0].JumlahSiaga;
                        this.JumlahWaspada = Response.data.content.data[0].JumlahWaspada;
                        this.JumlahAwas = Response.data.content.data[0].JumlahAwas;
                        this.jumlahpetugastma = Response.data.content.data[0].JumlahPetugas;
                        this.JumlahPetugasAktiftma = Response.data.content.data[0].JumlahPetugasAktif;
                        this.tanggalmaxtma = Response.data.content.data[0].Tanggalmaxdata;
                        this.tanggalmintma = Response.data.content.data[0].Tanggalmindata;
                        //alert(this.tanggalmintma );
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_tma-ResumeTMAAll?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.JumlahSTA = Response.data.content.data[0].JumlahSTA;
                        this.jumlahtma = Response.data.content.data[0].JumlahLokasi;
                        this.JumlahNormal = Response.data.content.data[0].JumlahNormal;
                        this.JumlahSiaga = Response.data.content.data[0].JumlahSiaga;
                        this.JumlahWaspada = Response.data.content.data[0].JumlahWaspada;
                        this.JumlahAwas = Response.data.content.data[0].JumlahAwas;
                        this.jumlahpetugastma = Response.data.content.data[0].JumlahPetugas;
                        this.JumlahPetugasAktiftma = Response.data.content.data[0].JumlahPetugasAktif;
                        this.tanggalmaxtma = Response.data.content.data[0].Tanggalmaxdata;
                        this.tanggalmintma = Response.data.content.data[0].Tanggalmindata;
                        //alert(this.tanggalmintma );
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        prosesdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ProsesEmonitoring/" + bytes);
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildataReff();
        this.ambildata();
    }
}
</script>
<style></style>