<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Lampiran</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <iframe src="" id="iframefile" style="width: 100%;height: 55vh;"></iframe>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-danger btn-sm lebar" data-dismiss="modal"><i
                                    class="fas fa-window-close"></i> Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
        </center>
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/STA">STA</a></li>
                                <li class="breadcrumb-item active">Pengaturan Data STA Sungai</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input STA Sungai</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab"
                                            role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="custom-tabs-umum-tab" data-toggle="pill"
                                                    href="#custom-tabs-umum" role="tab" aria-controls="custom-tabs-umum"
                                                    aria-selected="true">
                                                    Data Umum
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-tebing-tab" data-toggle="pill"
                                                    href="#custom-tabs-tebing" role="tab"
                                                    aria-controls="custom-tabs-tebing" aria-selected="false">
                                                    Data Tebing
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-sempadan-tab" data-toggle="pill"
                                                    href="#custom-tabs-sempadan" role="tab"
                                                    aria-controls="custom-tabs-sempadan" aria-selected="false">
                                                    Data Sempadan
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-banjir-tab" data-toggle="pill"
                                                    href="#custom-tabs-banjir" role="tab"
                                                    aria-controls="custom-tabs-banjir" aria-selected="false">
                                                    Data Bantaran Sungai
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="custom-tabs-palung-tab" data-toggle="pill"
                                                    href="#custom-tabs-palung" role="tab"
                                                    aria-controls="custom-tabs-palung" aria-selected="false">
                                                    Alur Dasar
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="custom-tabs-four-tabContent">
                                            <div class="tab-pane fade show active" id="custom-tabs-umum" role="tabpanel"
                                                aria-labelledby="custom-tabs-umum-tab">
                                                <div class="row px-2">
                                                    <div class="col-sm-12">&nbsp;</div>
                                                    <div class="container-fluid feature py-3">
                                                        <div class="section-title mb-0">
                                                            <div class="sub-style">
                                                                <h4 class="sub-title px-3 mb-0">
                                                                    DATA UMUM
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label>Sungai <small class="text-info"> (*wajib
                                                                dipilih)</small></label>
                                                        <v-select :options="sungai" :reduce="(label) => label.code"
                                                            label="label" v-model="defaultSelectedsungai"
                                                            @update:modelValue="onsungaiChange"
                                                            style="background-color: #ffffff;"></v-select>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label>Nama STA <small class="text-info"> (*wajib
                                                                diisi)</small></label>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i
                                                                        class="fab fa-creative-commons-share"></i></span>
                                                            </div>
                                                            <input type="text" class="form-control form-control-sm"
                                                                id="Nama" name="Nama" placeholder="Nama STA"
                                                                v-model="Nama">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label>Km STA <small class="text-info"> (*wajib
                                                                diisi)</small></label>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i
                                                                        class="fab fa-creative-commons-share"></i></span>
                                                            </div>
                                                            <input type="number" class="form-control form-control-sm"
                                                                id="STAKm" name="STAKm" placeholder="Km STA"
                                                                v-model="STAKm">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label>m STA <small class="text-info"> (*wajib
                                                                diisi)</small></label>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i
                                                                        class="fab fa-creative-commons-share"></i></span>
                                                            </div>
                                                            <input type="number" class="form-control form-control-sm"
                                                                id="STAmeter" name="STAmeter" placeholder="m STA"
                                                                v-model="STAmeter">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label>Longitude <small class="text-info"> (*wajib
                                                                diisi)</small></label>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i
                                                                        class="fab fa-creative-commons-share"></i></span>
                                                            </div>
                                                            <input type="number" class="form-control form-control-sm"
                                                                id="LONGITUDE" name="LONGITUDE" placeholder="Longitude"
                                                                v-model="LONGITUDE">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <label>Latitude <small class="text-info"> (*wajib
                                                                diisi)</small></label>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i
                                                                        class="fab fa-creative-commons-share"></i></span>
                                                            </div>
                                                            <input type="number" class="form-control form-control-sm"
                                                                id="LATITUDE" name="LATITUDE" placeholder="Latitude"
                                                                v-model="LATITUDE">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">&nbsp;</div>
                                                    <!--IKON PETA 1-->
                                                    <div class="col-sm-12"
                                                        style="background-color: #ADD8E6;padding-top: 10px;">
                                                        <label>Gambar Penampang Sungai<small class="text-info">
                                                                (*jpg)</small>
                                                        </label>
                                                        <label
                                                            v-if="(filenya_penampang != '') && (filenya_penampang != null)"
                                                            style="float: right;"> <a href="javascript:void(0)"
                                                                @click="lihatlampiran(filenya_penampang, 'parastapainnovation_sta-Gambar')"><i
                                                                    class="fas fa-file-image"></i> Lihat
                                                                Gambar</a></label>
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i
                                                                        class="far fa-file-image"></i></span>
                                                            </div>
                                                            <input type="file" class="form-control form-control-sm"
                                                                id="fileToUploadfilenya_penampang"
                                                                name="fileToUploadfilenya_penampang"
                                                                placeholder="fileToUpload" style="font-size: smaller;"
                                                                accept="image/*">
                                                            <input type="hidden" class="form-control form-control-sm"
                                                                id="filenya_penampang" name="filenya_penampang"
                                                                placeholder="File Beranda">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12"
                                                        style="background-color: #ADD8E6;padding-bottom: 10px;">
                                                        <label>Opsi</label>
                                                        <div class="row">
                                                            <div class="col-sm-9">
                                                                <div class="progress">
                                                                    <div class="progress-bar" role="progressbar"
                                                                        aria-valuenow="0" aria-valuemin="0"
                                                                        aria-valuemax="100" style="width:0%"
                                                                        id="persennyafilenya_penampang">
                                                                        <span
                                                                            id="tulisanpersenfilenya_penampang">0%</span>
                                                                    </div>
                                                                </div>
                                                                <br>
                                                                <span class="text-success"
                                                                    id="suksesnyafilenya_penampang"></span>
                                                            </div>
                                                            <div class="col-sm-3 text-center">
                                                                <button type="button"
                                                                    class="btn btn-warning btn-sm lebar text-white"
                                                                    @click="uploadfile('filenya_penampang', 'image/*')">Upload
                                                                    File</button>
                                                                <button type="button"
                                                                    class="btn btn-danger btn-sm lebar"
                                                                    @click="clearfilepeta('filenya_penampang')">Hapus
                                                                    File</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--IKON PETA 1 END-->
                                                    <div class="col-sm-12">&nbsp;</div>
                                                    <div class="col-sm-12">
                                                        <label>Keterangan</label>
                                                        <div class="row">
                                                            <div class="col-sm-12">
                                                                <quill-editor v-model:value="keterangan"
                                                                    style="height: 25vh;"></quill-editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">&nbsp;</div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="custom-tabs-tebing" role="tabpanel"
                                                aria-labelledby="custom-tabs-tebing-tab">
                                                <div class="row px-2">
                                                    <div class="col-sm-12">&nbsp;</div>
                                                    <div class="container-fluid feature py-3">
                                                        <div class="section-title mb-0">
                                                            <div class="sub-style">
                                                                <h4 class="sub-title px-3 mb-0">
                                                                    DATA TEBING
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <fieldset>
                                                        <legend>Fisik Kiri</legend>
                                                        <div class="row px-2">
                                                            <div class="col-sm-6">
                                                                <label>Lereng longsor <small class="text-info"> (*wajib
                                                                        diisi)</small></label>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i
                                                                                class="fab fa-creative-commons-share"></i></span>
                                                                    </div>
                                                                    <input type="number"
                                                                        class="form-control form-control-sm"
                                                                        id="lereng_longsor_ki" name="lereng_longsor_ki"
                                                                        placeholder="Lereng longsor"
                                                                        v-model="lereng_longsor_ki">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <label>Kepadatan tanaman <small class="text-info">
                                                                        (*wajib
                                                                        diisi)</small></label>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i
                                                                                class="fab fa-creative-commons-share"></i></span>
                                                                    </div>
                                                                    <input type="number"
                                                                        class="form-control form-control-sm"
                                                                        id="padat_tanaman_ki" name="padat_tanaman_ki"
                                                                        placeholder="Kepadatan tanaman"
                                                                        v-model="padat_tanaman_ki">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset>
                                                        <legend>Fisik Kanan</legend>
                                                        <div class="row px-2">
                                                            <div class="col-sm-6">
                                                                <label>Lereng longsor <small class="text-info"> (*wajib
                                                                        diisi)</small></label>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i
                                                                                class="fab fa-creative-commons-share"></i></span>
                                                                    </div>
                                                                    <input type="number"
                                                                        class="form-control form-control-sm"
                                                                        id="lereng_longsor_ka" name="lereng_longsor_ka"
                                                                        placeholder="Lereng longsor"
                                                                        v-model="lereng_longsor_ka">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <label>Kepadatan tanaman <small class="text-info">
                                                                        (*wajib
                                                                        diisi)</small></label>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i
                                                                                class="fab fa-creative-commons-share"></i></span>
                                                                    </div>
                                                                    <input type="number"
                                                                        class="form-control form-control-sm"
                                                                        id="padat_tanaman_ka" name="padat_tanaman_ka"
                                                                        placeholder="Kepadatan tanaman"
                                                                        v-model="padat_tanaman_ka">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset>
                                                        <legend>Fungsi</legend>
                                                        <div class="row px-2">
                                                            <div class="col-sm-6">
                                                                <label>Dasar tergerus <small class="text-info"> (*wajib
                                                                        diisi)</small></label>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i
                                                                                class="fab fa-creative-commons-share"></i></span>
                                                                    </div>
                                                                    <input type="number"
                                                                        class="form-control form-control-sm"
                                                                        id="dasar_tergerus" name="dasar_tergerus"
                                                                        placeholder="Dasar tergerus"
                                                                        v-model="dasar_tergerus">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <label>Fungsi Tebing <small class="text-info">
                                                                        (*wajib
                                                                        diisi)</small></label>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i
                                                                                class="fab fa-creative-commons-share"></i></span>
                                                                    </div>
                                                                    <input type="number"
                                                                        class="form-control form-control-sm"
                                                                        id="fungsi_tebing" name="fungsi_tebing"
                                                                        placeholder="Fungsi Tebing"
                                                                        v-model="fungsi_tebing">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="custom-tabs-sempadan" role="tabpanel"
                                                aria-labelledby="custom-tabs-sempadan-tab">
                                                <div class="row px-2">
                                                    <div class="col-sm-12">&nbsp;</div>
                                                    <div class="container-fluid feature py-3">
                                                        <div class="section-title mb-0">
                                                            <div class="sub-style">
                                                                <h4 class="sub-title px-3 mb-0">
                                                                    DATA SEMPADAN
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <fieldset>
                                                        <legend>Sempadan Kiri</legend>
                                                        <div class="row px-2">
                                                            <div class="col-sm-4">
                                                                <!-- checkbox -->
                                                                <div class="form-group">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            id="non_kota_alami_ki"
                                                                            v-model="non_kota_alami_ki">
                                                                        <label class="form-check-label">Non Perkotaan
                                                                            Alami</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <!-- checkbox -->
                                                                <div class="form-group">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            id="non_kota_berkembang_ki"
                                                                            v-model="non_kota_berkembang_ki">
                                                                        <label class="form-check-label">Non Perkotaan
                                                                            Berkembang</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <!-- checkbox -->
                                                                <div class="form-group">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            id="kota_ki" v-model="kota_ki">
                                                                        <label
                                                                            class="form-check-label">Perkotaan</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset>
                                                        <legend>Sempadan Kanan</legend>
                                                        <div class="row px-2">
                                                            <div class="col-sm-4">
                                                                <!-- checkbox -->
                                                                <div class="form-group">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            id="non_kota_alami_ka"
                                                                            v-model="non_kota_alami_ka">
                                                                        <label class="form-check-label">Non Perkotaan
                                                                            Alami</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <!-- checkbox -->
                                                                <div class="form-group">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            id="non_kota_berkembang_ka"
                                                                            v-model="non_kota_berkembang_ka">
                                                                        <label class="form-check-label">Non Perkotaan
                                                                            Berkembang</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <!-- checkbox -->
                                                                <div class="form-group">
                                                                    <div class="form-check">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            id="kota_ka" v-model="kota_ka">
                                                                        <label
                                                                            class="form-check-label">Perkotaan</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <fieldset>
                                                        <legend>Fungsi Sempadan</legend>
                                                        <div class="row px-2">
                                                            <div class="col-sm-6">
                                                                <label>Pemanfaatan daerah <small class="text-info">
                                                                        (*wajib
                                                                        diisi)</small></label>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i
                                                                                class="fab fa-creative-commons-share"></i></span>
                                                                    </div>
                                                                    <input type="number"
                                                                        class="form-control form-control-sm"
                                                                        id="manfaat_sempadan" name="manfaat_sempadan"
                                                                        placeholder="Pemanfaatan daerah"
                                                                        v-model="manfaat_sempadan">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-6">
                                                                <label>Fungsi sempadan <small class="text-info">
                                                                        (*wajib
                                                                        diisi)</small></label>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i
                                                                                class="fab fa-creative-commons-share"></i></span>
                                                                    </div>
                                                                    <input type="number"
                                                                        class="form-control form-control-sm"
                                                                        id="fungsi_sempadan" name="fungsi_sempadan"
                                                                        placeholder="PFungsi sempadan"
                                                                        v-model="fungsi_sempadan">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="custom-tabs-banjir" role="tabpanel"
                                                aria-labelledby="custom-tabs-banjir-tab">
                                                <div class="row px-2">
                                                    <div class="col-sm-12">&nbsp;</div>
                                                    <div class="container-fluid feature py-3">
                                                        <div class="section-title mb-0">
                                                            <div class="sub-style">
                                                                <h4 class="sub-title px-3 mb-0">
                                                                    DATA BANTARAN SUNGAI
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row px-2">
                                                        <div class="col-sm-6">
                                                            <label>Penambangan Sedimen <small class="text-info">
                                                                    (*wajib
                                                                    diisi)</small></label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fab fa-creative-commons-share"></i></span>
                                                                </div>
                                                                <input type="number"
                                                                    class="form-control form-control-sm"
                                                                    id="penambangan_sedimen" name="penambangan_sedimen"
                                                                    placeholder="Penambangan Sedimen"
                                                                    v-model="penambangan_sedimen">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <label>Fungsi Bantaran <small class="text-info">
                                                                    (*wajib
                                                                    diisi)</small></label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fab fa-creative-commons-share"></i></span>
                                                                </div>
                                                                <input type="number"
                                                                    class="form-control form-control-sm"
                                                                    id="fungsi_bantaran" name="fungsi_bantaran"
                                                                    placeholder="Fungsi Bantaran"
                                                                    v-model="fungsi_bantaran">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="custom-tabs-palung" role="tabpanel"
                                                aria-labelledby="custom-tabs-palung-tab">
                                                <div class="row px-2">
                                                    <div class="col-sm-12">&nbsp;</div>
                                                    <div class="container-fluid feature py-3">
                                                        <div class="section-title mb-0">
                                                            <div class="sub-style">
                                                                <h4 class="sub-title px-3 mb-0">
                                                                    ALUR DASAR
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row px-2">
                                                        <div class="col-sm-6">
                                                            <label>Hambatan Aliran Air <small class="text-info">
                                                                    (*wajib
                                                                    diisi)</small></label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fab fa-creative-commons-share"></i></span>
                                                                </div>
                                                                <input type="number"
                                                                    class="form-control form-control-sm"
                                                                    id="hambatan_air" name="hambatan_air"
                                                                    placeholder="Hambatan Aliran Air"
                                                                    v-model="hambatan_air">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <label>Penyempitan <small class="text-info">
                                                                    (*wajib
                                                                    diisi)</small></label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fab fa-creative-commons-share"></i></span>
                                                                </div>
                                                                <input type="number"
                                                                    class="form-control form-control-sm"
                                                                    id="penyempitan" name="penyempitan"
                                                                    placeholder="Penyempitan" v-model="penyempitan">
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <label>Fungsi Alur Dasar <small class="text-info">
                                                                    (*wajib
                                                                    diisi)</small></label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fab fa-creative-commons-share"></i></span>
                                                                </div>
                                                                <input type="number"
                                                                    class="form-control form-control-sm"
                                                                    id="fungsi_dasar" name="fungsi_dasar"
                                                                    placeholder="Fungsi Alur Dasar"
                                                                    v-model="fungsi_dasar">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(mode == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(mode != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">&nbsp;</div>
                <div class="col-sm-12">&nbsp;</div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            Nama: '',
            STAKm: 0,
            STAmeter: 0,
            LONGITUDE: 0,
            LATITUDE: 0,
            KODE_PROV: 0,
            KODE_KK: 0,
            KODE_KEC: 0,
            KODE_KD: 0,
            filenya_penampang: '',
            keterangan: '',
            datasungai: [],
            sungai: [],
            defaultSelectedsungai: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedsungai: 0,
            lereng_longsor_ki: 0,
            padat_tanaman_ki: 0,
            lereng_longsor_ka: 0,
            padat_tanaman_ka: 0,
            dasar_tergerus: 0,
            fungsi_tebing: 0,

            non_kota_alami_ki: false,
            non_kota_berkembang_ki: false,
            kota_ki: false,
            non_kota_alami_ka: false,
            non_kota_berkembang_ka: false,
            kota_ka: false,
            manfaat_sempadan: 0,
            fungsi_sempadan: 0,
            penambangan_sedimen: 0,
            fungsi_bantaran: 0,

            hambatan_air: 0,
            penyempitan: 0,
            fungsi_dasar: 0,
            mode: '',
            validasi: false,
        }
    },
    methods: {
        lihatlampiran(filenya, alamat) {
            this.halamanloading = true;
            document.getElementById('iframefile').src = "";
            //alert(process.env.VUE_APP_URL_API + foldernya + '?f=' + filenya);
            document.getElementById('iframefile').src = process.env.VUE_APP_URL_API + alamat + '?f=' + filenya;
            $('#costumModaldisclaimer').modal('show');
            this.halamanloading = false;
        },
        onsungaiChange(a) {
            try {
                this.selectedsungai = this.sungai[a].tag;
            }
            catch (err) {
                return;
            }
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            var parameter = this.secretencData.split("|");
            var kodesungai = parameter[1];
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kodesungai, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/STA/" + bytes);
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';
                    this.filenya_beranda = '';
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async AmbilDataReff() {
            this.halamanloading = true;
            var mainAPIDataReff = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataReff.get("parastapainnovation_sungai-GetSungai?random=" + random).then(
                Response => {
                    this.datasungai = [];
                    this.sungai = [];
                    this.datasungai = Response.data.content.data;
                    this.datasungai.forEach((item) => {
                        this.sungai.push({
                            label: item.Nama,
                            code: this.datasungai.indexOf(item) + 1,
                            tag: item.id,
                        });
                    });
                    this.sungai.unshift({
                        label: 'Pilih Data',
                        code: 0,
                        tag: '',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datasungai = [];
                this.sungai = [];
                console.log(error);
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            var parameter = this.secretencData.split("|");
            var kodesta = parameter[0];
            await mainAPIDataSingel.get("parastapainnovation_sta-GetSTAByID?id=" + kodesta + "&random=" + random).then(
                Response => {
                    this.Nama = Response.data.content.data[0].NamaSTA;
                    this.keterangan = Response.data.content.data[0].Keterangan;
                    this.STAKm = Response.data.content.data[0].STAKm;
                    this.STAmeter = Response.data.content.data[0].STAmeter;
                    this.LONGITUDE = Response.data.content.data[0].LONGITUDE;
                    this.LATITUDE = Response.data.content.data[0].LATITUDE;
                    this.defaultSelectedsungai = {
                        code: Response.data.content.data[0].idSungai,
                        label: Response.data.content.data[0].NamaSungai,
                        tag: Response.data.content.data[0].idSungai,
                    };
                    this.selectedsungai = Response.data.content.data[0].idSungai;
                    this.filenya_penampang = Response.data.content.data[0].filenya_penampang;
                    document.getElementById('filenya_penampang').value = Response.data.content.data[0].filenya_penampang;

                    this.lereng_longsor_ki = Response.data.content.data[0].lereng_longsor_ki;
                    this.padat_tanaman_ki = Response.data.content.data[0].padat_tanaman_ki;
                    this.lereng_longsor_ka = Response.data.content.data[0].lereng_longsor_ka;
                    this.padat_tanaman_ka = Response.data.content.data[0].padat_tanaman_ka;
                    this.dasar_tergerus = Response.data.content.data[0].dasar_tergerus;
                    this.fungsi_tebing = Response.data.content.data[0].fungsi_tebing;

                    if (Response.data.content.data[0].non_kota_alami_ki == 1) {
                        this.non_kota_alami_ki = true;
                    }
                    if (Response.data.content.data[0].non_kota_berkembang_ki == 1) {
                        this.non_kota_berkembang_ki = true;
                    }
                    if (Response.data.content.data[0].kota_ki == 1) {
                        this.kota_ki = true;
                    }
                    if (Response.data.content.data[0].non_kota_alami_ka == 1) {
                        this.non_kota_alami_ka = true;
                    }
                    if (Response.data.content.data[0].non_kota_berkembang_ka == 1) {
                        this.non_kota_berkembang_ka = true;
                    }
                    if (Response.data.content.data[0].kota_ka == 1) {
                        this.kota_ka = true;
                    }

                    this.manfaat_sempadan = Response.data.content.data[0].manfaat_sempadan;
                    this.fungsi_sempadan = Response.data.content.data[0].fungsi_sempadan;
                    this.penambangan_sedimen = Response.data.content.data[0].penambangan_sedimen;
                    this.fungsi_bantaran = Response.data.content.data[0].fungsi_bantaran;

                    this.hambatan_air = Response.data.content.data[0].hambatan_air;
                    this.penyempitan = Response.data.content.data[0].penyempitan;
                    this.fungsi_dasar = Response.data.content.data[0].fungsi_dasar;

                    this.halamanloading = false;
                }
            ).catch(error => {
                //console.log(error);
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if ((this.selectedsungai == '') || (this.selectedsungai == undefined) || (this.selectedsungai == null)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Data',
                });
                return false;
            }
            if (document.getElementById('Nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('Nama').focus();
                return false;
            }
            if (document.getElementById('STAKm').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('STAKm').focus();
                return false;
            }
            if (document.getElementById('STAmeter').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('STAmeter').focus();
                return false;
            }
            if (document.getElementById('LONGITUDE').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('LONGITUDE').focus();
                return false;
            }
            if (document.getElementById('LATITUDE').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('LATITUDE').focus();
                return false;
            }

            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var parameter = this.secretencData.split("|");
            var kodesungai = parameter[1];
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kodesungai, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());

            var fd = new FormData();
            fd.append("nama", this.nama);
            fd.append("kode", this.kode);
            fd.append("panjang", this.panjang);
            fd.append("das", this.das);
            fd.append("status", this.status);
            fd.append("luasha", this.luasha);
            fd.append("filenya_penampang", document.getElementById('filenya_penampang').value);
            fd.append("keterangan", this.keterangan);
            fd.append("balai", this.selectedbalai);
            fd.append("sungai", this.selectedsungai);
            fd.append("lereng_longsor_ki", this.lereng_longsor_ki);
            fd.append("padat_tanaman_ki", this.padat_tanaman_ki);
            fd.append("lereng_longsor_ka", this.lereng_longsor_ka);
            fd.append("padat_tanaman_ka", this.padat_tanaman_ka);
            fd.append("dasar_tergerus", this.dasar_tergerus);
            fd.append("fungsi_tebing", this.fungsi_tebing);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_sungai-AddSungai", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/STA/" + bytes);
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var parameter = this.secretencData.split("|");
            var kodesungai = parameter[1];
            var kode = parameter[0];
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kodesungai, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());

            var non_kota_alami_ki = 0;
            var non_kota_berkembang_ki = 0;
            var kota_ki = 0;
            var non_kota_alami_ka = 0;
            var non_kota_berkembang_ka = 0;
            var kota_ka = 0;

            if (document.getElementById('non_kota_alami_ki').checked == true) {
                non_kota_alami_ki = 1;
            }
            if (document.getElementById('non_kota_berkembang_ki').checked == true) {
                non_kota_berkembang_ki = 1;
            }
            if (document.getElementById('kota_ki').checked == true) {
                kota_ki = 1;
            }
            if (document.getElementById('non_kota_alami_ka').checked == true) {
                non_kota_alami_ka = 1;
            }
            if (document.getElementById('non_kota_berkembang_ka').checked == true) {
                non_kota_berkembang_ka = 1;
            }
            if (document.getElementById('kota_ka').checked == true) {
                kota_ka = 1;
            }

            var fd = new FormData();
            fd.append("Sungai", this.selectedsungai);
            fd.append("Nama", this.Nama);
            fd.append("STAKm", this.STAKm);
            fd.append("STAmeter", this.STAmeter);
            fd.append("LONGITUDE", this.LONGITUDE);
            fd.append("LATITUDE", this.LATITUDE);
            fd.append("filenya_penampang", document.getElementById('filenya_penampang').value);
            fd.append("keterangan", this.keterangan);
            fd.append("lereng_longsor_ki", this.lereng_longsor_ki);
            fd.append("padat_tanaman_ki", this.padat_tanaman_ki);
            fd.append("lereng_longsor_ka", this.lereng_longsor_ka);
            fd.append("padat_tanaman_ka", this.padat_tanaman_ka);
            fd.append("dasar_tergerus", this.dasar_tergerus);
            fd.append("fungsi_tebing", this.fungsi_tebing);

            fd.append("non_kota_alami_ki", non_kota_alami_ki);
            fd.append("non_kota_berkembang_ki", non_kota_berkembang_ki);
            fd.append("kota_ki", kota_ki);
            fd.append("non_kota_alami_ka", non_kota_alami_ka);
            fd.append("non_kota_berkembang_ka", non_kota_berkembang_ka);
            fd.append("kota_ka", kota_ka);
            fd.append("manfaat_sempadan", this.manfaat_sempadan);
            fd.append("fungsi_sempadan", this.fungsi_sempadan);
            fd.append("penambangan_sedimen", this.penambangan_sedimen);
            fd.append("fungsi_bantaran", this.fungsi_bantaran);
            fd.append("hambatan_air", this.hambatan_air);
            fd.append("penyempitan", this.penyempitan);
            fd.append("fungsi_dasar", this.fungsi_dasar);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", kode);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_sta-UpdateSTA", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/STA/" + bytes);
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.AmbilDataReff();
        //alert(this.secretencData);
        var cek = this.secretencData.split('|');
        this.mode=cek[0];
        if (this.mode != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>