import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KontakKami',
    name: 'KontakKami',
    component: () => import('../../components/views/Publik/KontakKami.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarRegulasi',
    name: 'DaftarRegulasi',
    component: () => import('../../components/views/Publik/DaftarRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DaftarSungai',
    name: 'DaftarSungai',
    component: () => import('../../components/views/Publik/DaftarSungai.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/GIS/:id',
    name: 'GIS',
    component: () => import('../../components/views/Publik/GIS.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Slider',
    name: 'Slider',
    component: () => import('../../components/views/Backoffice/Slider/Slider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSlider/:id',
    name: 'ProsesSlider',
    component: () => import('../../components/views/Backoffice/Slider/ProsesSlider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Emonitoring',
    name: 'Emonitoring',
    component: () => import('../../components/views/Backoffice/Emonitoring/Emonitoring.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesEmonitoring/:id',
    name: 'ProsesEmonitoring',
    component: () => import('../../components/views/Backoffice/Emonitoring/ProsesEmonitoring.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Regulasi',
    name: 'Regulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/Regulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesRegulasi/:id',
    name: 'ProsesRegulasi',
    component: () => import('../../components/views/Backoffice/Regulasi/ProsesRegulasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Prasarana',
    name: 'Prasarana',
    component: () => import('../../components/views/Backoffice/Prasarana/Prasarana.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPrasarana/:id',
    name: 'ProsesPrasarana',
    component: () => import('../../components/views/Backoffice/Prasarana/ProsesPrasarana.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/TitikKritis',
    name: 'TitikKritis',
    component: () => import('../../components/views/Backoffice/TitikKritis/TitikKritis.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesTitikKritis/:id',
    name: 'ProsesTitikKritis',
    component: () => import('../../components/views/Backoffice/TitikKritis/ProsesTitikKritis.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InfoBeranda',
    name: 'InfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/InfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfoBeranda/:id',
    name: 'ProsesInfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Sungai',
    name: 'Sungai',
    component: () => import('../../components/views/Backoffice/Sungai/Sungai.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSungai/:id',
    name: 'ProsesSungai',
    component: () => import('../../components/views/Backoffice/Sungai/ProsesSungai.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/STA/:id',
    name: 'STA',
    component: () => import('../../components/views/Backoffice/STA/STA.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSTA/:id',
    name: 'ProsesSTA',
    component: () => import('../../components/views/Backoffice/STA/ProsesSTA.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/TMA',
    name: 'TMA',
    component: () => import('../../components/views/Backoffice/TMA/TMA.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesTMA/:id',
    name: 'ProsesTMA',
    component: () => import('../../components/views/Backoffice/TMA/ProsesTMA.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KategoriData',
    name: 'KategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/KategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategoriData/:id',
    name: 'ProsesKategoriData',
    component: () => import('../../components/views/Backoffice/KategoriData/ProsesKategoriData.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/APIPeta',
    name: 'APIPeta',
    component: () => import('../../components/views/Backoffice/APIPeta/APIPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesAPIPeta/:id',
    name: 'ProsesAPIPeta',
    component: () => import('../../components/views/Backoffice/APIPeta/ProsesAPIPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/LayerPeta',
    name: 'LayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/LayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLayerPeta/:id',
    name: 'ProsesLayerPeta',
    component: () => import('../../components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../../components/views/Backoffice/User/User.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesUser/:id',
    name: 'ProsesUser',
    component: () => import('../../components/views/Backoffice/User/ProsesUser.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/GISAdmin/:id',
    name: 'GISAdmin',
    component: () => import('../../components/views/Backoffice/GIS/GIS.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
