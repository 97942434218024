<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/User">Pengguna</a></li>
                                <li class="breadcrumb-item active">Pengaturan Pengguna</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Pengguna</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Username <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm"
                                                    id="usernamedata" name="usernamedata" placeholder="Username"
                                                    v-model="usernamedata">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="usernamedatatmp" name="usernamedatatmp" placeholder="Username"
                                                    v-model="usernamedatatmp">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Password User<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="password" class="form-control form-control-sm"
                                                    id="passworddata" name="passworddata" placeholder="Password User"
                                                    v-model="passworddata">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama User<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="namadata"
                                                    name="namadata" placeholder="Nama User" v-model="namadata">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Level User<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <v-select :options="leveluser" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedleveluser"
                                                @update:modelValue="onleveluserChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6" v-if="this.selectedleveluser > 7">
                                            <label>Sungai <small class="text-info"> (*wajib
                                                    dipilih)</small>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="pilihsungai"
                                                        @click="pilihsungai()">
                                                    <label class="form-check-label">Pilih semua</label>
                                                </div>
                                            </label>
                                            <v-select multiple :options="sungai" :reduce="(label) => label.code"
                                                label="label" @option:selected="onsungaiChangeSelect($event)"
                                                @option:deselected="onsungaiChangeDeselect($event)"
                                                v-model="arraynamasungai" style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6" v-if="this.selectedleveluser > 7">
                                            <label>STA <small class="text-info"> (*wajib
                                                    dipilih)</small>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="pilihsta"
                                                        @click="pilihsta()">
                                                    <label class="form-check-label">Pilih semua</label>
                                                </div>
                                            </label>
                                            <v-select multiple :options="sta" :reduce="(label) => label.code"
                                                label="label" @option:selected="onstaChangeSelect($event)"
                                                @option:deselected="onstaChangeDeselect($event)" v-model="arraynamasta"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-12">
                                            &nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            dleveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            levelkabkot: JSON.parse(localStorage.getItem("bdlsistem")),
            levelkelurahan: JSON.parse(localStorage.getItem("sbdsistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            usernamedata: '',
            usernamedatatmp: '',
            namadata: '',
            passworddata: '',
            leveluser: [],
            dataleveluser: [],
            defaultSelectedleveluser: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedleveluser: 0,
            kabkot: [],
            datakabkot: [],
            defaultSelectedkabkot: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedkabkot: 0,
            datasungai: [],
            sungai: [],
            defaultSelectedsungai: {
            },
            selectedsungai: '0',
            paramselectedsungai: [],
            datasta: [],
            sta: [],
            defaultSelectedsta: {
            },
            selectedsta: '0',
            paramselectedsta: [],
            validasi: false,
            arraynamasta: [],
            arraynamasungai: [],
        }
    },
    methods: {
        async pilihsungai() {
            if (document.getElementById('pilihsungai').checked == true) {
                this.arraynamasungai = [];
                this.halamanloading = true;
                var mainAPIDataReff = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                var random = Math.random();
                await mainAPIDataReff.get("parastapainnovation_sungai-GetSungai?random=" + random).then(
                    Response => {
                        this.datasungai = [];
                        this.sungai = [];
                        this.datasungai = Response.data.content.data;
                        this.datasungai.forEach((item) => {
                            this.sungai.push({
                                label: item.Nama,
                                code: this.datasungai.indexOf(item),
                                tag: item.id,
                            });
                            this.arraynamasungai.push({
                                label: item.Nama,
                                code: this.datasungai.indexOf(item),
                                tag: item.id,
                            });
                        });

                        this.paramselectedsungai = [];
                        for (let i = 0; i < this.sungai.length; i++) {
                            this.paramselectedsungai.push(parseInt(this.sungai[i].tag));
                        }
                        document.getElementById('pilihsta').checked = false;
                        this.AmbilDataSTA(this.paramselectedsungai);
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datasungai = [];
                    this.sungai = [];
                    console.log(error);
                    swal.fire('Peringatan', error, 'error');
                    this.halamanloading = false;
                    return false;
                })
            }
            else {
                this.paramselectedsungai = [];
                this.arraynamasungai = [];
            }
        },
        async pilihsta() {
            if (document.getElementById('pilihsta').checked == true) {
                if (this.sungai.length == 0) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Pilih Sungai',
                    });
                    document.getElementById('pilihsta').checked = false;
                    return false;
                }
                this.paramselectedsungai = [];
                for (let i = 0; i < this.sungai.length; i++) {
                    this.paramselectedsungai.push(parseInt(this.sungai[i].tag));
                }
                
                this.halamanloading = true;
                var mainAPIDataReff = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                var random = Math.random();
                await mainAPIDataReff.get("parastapainnovation_sta-AllGetSTA?random=" + random + "&sungai=" + this.paramselectedsungai).then(
                    Response => {
                        this.datasta = [];
                        this.sta = [];
                        this.arraynamasta = [];
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datasta = Response.data.content.data;
                            this.datasta.forEach((item) => {
                                this.sta.push({
                                    label: item.Nama,
                                    code: this.datasta.indexOf(item),
                                    tag: item.id,
                                });
                                if (item == 0) {
                                    this.defaultSelectedsta = {
                                        label: item.Nama,
                                        code: this.datasta.indexOf(item),
                                        tag: item.id
                                    };
                                    this.selectedsta = item.id;
                                }

                                this.arraynamasta.push({
                                    label: item.Nama,
                                    code: this.datasta.indexOf(item),
                                    tag: item.id,
                                });

                                this.paramselectedsta.push(item.id);
                            });
                        } else {
                            this.datasta = [];
                            this.sta = [];
                            this.defaultSelectedsta = {};
                            this.selectedsta = '0';
                            this.paramselectedsta = [];
                        }

                        this.halamanloading = false;
                    }
                ).catch(error => {
                    this.datasta = [];
                    this.sta = [];
                    console.log(error);
                    swal.fire('Peringatan', error, 'error');
                    this.halamanloading = false;
                    return false;
                })
            }
            else {
                this.paramselectedsta = [];
                this.arraynamasta = [];
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        onsungaiChangeSelect(events) {
            this.paramselectedsungai = [];
            for (let i = 0; i < events.length; i++) {
                this.paramselectedsungai.push(parseInt(events[i].tag));
            }
            document.getElementById('pilihsta').checked = false;
            this.AmbilDataSTA(this.paramselectedsungai);
            //alert(JSON.stringify(this.paramselectedsungai));
        },
        onsungaiChangeDeselect(events) {
            //alert(JSON.stringify(events));
            var temp = [];
            temp.push(events);
            //alert(JSON.stringify(temp));
            var yghilang = '';
            for (let i = 0; i < temp.length; i++) {
                yghilang = parseInt(temp[i].tag);
            }
            var temparray = [];
            for (let i = 0; i < this.paramselectedsungai.length; i++) {
                if (this.paramselectedsungai[i] !== yghilang) {
                    temparray.push(this.paramselectedsungai[i]);
                }
            }
            document.getElementById('pilihsta').checked = false;
            this.paramselectedsungai = temparray;
            this.AmbilDataSTA(this.paramselectedsungai);
            //alert(JSON.stringify(this.paramselectedsungai))
        },
        onstaChangeSelect(events) {
            this.paramselectedsta = [];
            for (let i = 0; i < events.length; i++) {
                this.paramselectedsta.push(parseInt(events[i].tag));
            }
            //alert(JSON.stringify(this.paramselectedsta));
        },
        onstaChangeDeselect(events) {
            //alert(JSON.stringify(events));
            var temp = [];
            temp.push(events);
            //alert(JSON.stringify(temp));
            var yghilang = '';
            for (let i = 0; i < temp.length; i++) {
                yghilang = parseInt(temp[i].tag);
            }
            var temparray = [];
            for (let i = 0; i < this.paramselectedsta.length; i++) {
                if (this.paramselectedsta[i] !== yghilang) {
                    temparray.push(this.paramselectedsta[i]);
                }
            }
            this.paramselectedsta = temparray;
            //alert(JSON.stringify(this.paramselectedsta))
        },
        onleveluserChange(a) {
            try {
                this.selectedleveluser = this.leveluser[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkabkotChange(a) {
            try {
                this.selectedkabkot = this.kabkot[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/User");
        },
        async AmbilDataReff() {
            this.halamanloading = true;
            var mainAPIDataReff = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataReff.get("parastapainnovation_sungai-GetSungai?random=" + random).then(
                Response => {
                    this.datasungai = [];
                    this.sungai = [];
                    this.datasungai = Response.data.content.data;
                    this.datasungai.forEach((item) => {
                        this.sungai.push({
                            label: item.Nama,
                            code: this.datasungai.indexOf(item),
                            tag: item.id,
                        });
                        if (item == 0) {
                            this.defaultSelectedsungai = {
                                label: item.Nama,
                                code: this.datasungai.indexOf(item),
                                tag: item.id
                            };
                            this.selectedsungai = item.id;
                        }
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datasungai = [];
                this.sungai = [];
                console.log(error);
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })

        },
        async AmbilDataSTA(idsungai) {
            this.halamanloading = true;
            var mainAPIDataReff = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataReff.get("parastapainnovation_sta-AllGetSTA?random=" + random + "&sungai=" + idsungai).then(
                Response => {
                    this.datasta = [];
                    this.sta = [];
                    this.arraynamasta = [];
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datasta = Response.data.content.data;
                        this.datasta.forEach((item) => {
                            this.sta.push({
                                label: item.Nama,
                                code: this.datasta.indexOf(item),
                                tag: item.id,
                            });
                            if (item == 0) {
                                this.defaultSelectedsta = {
                                    label: item.Nama,
                                    code: this.datasta.indexOf(item),
                                    tag: item.id
                                };
                                this.selectedsta = item.id;
                            }
                        });
                    } else {
                        this.datasta = [];
                        this.sta = [];
                        this.defaultSelectedsta = {};
                        this.selectedsta = '0';
                        this.paramselectedsta = [];
                    }

                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datasta = [];
                this.sta = [];
                console.log(error);
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async AmbilData() {
            this.halamanloading = true;
            var mainAPIData = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIData.get("parastapainnovation-GetAllLevel?level=" + this.dleveluser + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataleveluser = [];
                        this.leveluser = [];
                        this.dataleveluser = Response.data.content.data;
                        this.dataleveluser.forEach((item) => {
                            this.leveluser.push({
                                label: item.nama,
                                code: this.dataleveluser.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.leveluser.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIData = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIData.get("parastapainnovation-GetAllLevel?level=" + this.dleveluser + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataleveluser = [];
                        this.leveluser = [];
                        this.dataleveluser = Response.data.content.data;
                        this.dataleveluser.forEach((item) => {
                            this.leveluser.push({
                                label: item.nama,
                                code: this.dataleveluser.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.leveluser.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })


            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovationuserNonMD5-GetUser?kduser=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.usernamedata = Response.data.content.data[0].username;
                    this.usernamedatatmp = Response.data.content.data[0].username;
                    this.namadata = Response.data.content.data[0].nama;
                    this.passworddata = Response.data.content.data[0].password;
                    //this.sungai = Response.data.content.data[0].sungai;
                    //this.sta = Response.data.content.data[0].sta;
                    this.defaultSelectedleveluser = {
                        code: Response.data.content.data[0].level,
                        label: Response.data.content.data[0].namaleveluser,
                        tag: Response.data.content.data[0].level,
                    };
                    this.selectedleveluser = Response.data.content.data[0].level;
                    //alert(Response.data.content.data[0].namasungai);
                    this.sungai = [];
                    this.arraynamasungai = Response.data.content.data[0].arraynamasungai;
                    if (Response.data.content.data[0].arraynamasungai != null) {
                        Response.data.content.data[0].namasungai.forEach((item) => {
                            this.sungai.push({
                                label: item.Nama,
                                code: Response.data.content.data[0].namasungai.indexOf(item),
                                tag: item.id,
                            });
                            if (item == 0) {
                                this.defaultSelectedsungai = {
                                    label: item.Nama,
                                    code: Response.data.content.data[0].namasungai.indexOf(item),
                                    tag: item.id
                                };
                                this.selectedsungai = item.id;
                            }
                        });
                        this.sta = [];
                        this.arraynamasta = Response.data.content.data[0].arraynamasta;
                        Response.data.content.data[0].namasta.forEach((item) => {
                            this.sta.push({
                                label: item.Nama,
                                code: Response.data.content.data[0].namasta.indexOf(item),
                                tag: item.id,
                            });
                            if (item == 0) {
                                this.defaultSelectedsta = {
                                    label: item.Nama,
                                    code: Response.data.content.data[0].namasta.indexOf(item),
                                    tag: item.id
                                };
                                this.selectedsta = item.id;
                            }
                        });
                    }

                    this.halamanloading = false;

                }
            ).catch(error => {
                swal.fire('Peringatan', JSON.stringify(error), 'error');
                //console.log(error);
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if (document.getElementById('usernamedata').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('usernamedata').focus();
                return false;
            }
            if (document.getElementById('passworddata').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('passworddata').focus();
                return false;
            }
            if (document.getElementById('namadata').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('namadata').focus();
                return false;
            }
            if (this.selectedleveluser == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                return false;
            }

            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            
            var fd = new FormData();
            fd.append("username", this.usernamedata);
            fd.append("password", this.passworddata);
            fd.append("nama", this.namadata);
            fd.append("level", this.selectedleveluser);
            fd.append("kabkot", this.selectedkabkot);
            fd.append("sta", this.paramselectedsta);
            fd.append("sungai", this.paramselectedsungai);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovationuser-AddUser", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/User");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {

            if (this.paramselectedsta == '') {
                for (let i = 0; i < this.arraynamasta.length; i++) {
                    this.paramselectedsta.push(this.arraynamasta[i].tag);
                }
            }
            if (this.paramselectedsungai == '') {
                for (let i = 0; i < this.arraynamasungai.length; i++) {
                    this.paramselectedsungai.push(this.arraynamasungai[i].tag);
                }
            }

            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            //alert(this.paramselectedsungai);
            //alert(this.paramselectedsta);
            //return false;
            var fd = new FormData();
            fd.append("username", this.usernamedata);
            fd.append("usernametmp", this.usernamedatatmp);
            fd.append("password", this.passworddata);
            fd.append("nama", this.namadata);
            fd.append("level", this.selectedleveluser);
            fd.append("kabkot", this.selectedkabkot);
            fd.append("sta", this.paramselectedsta);
            fd.append("sungai", this.paramselectedsungai);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovationuser-EditUser", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/User");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.AmbilDataReff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
        else {
            this.AmbilData();
        }
    }
}
</script>
<style></style>