<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Dokumentasi</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                @click="tutupmodal()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <iframe src="" id="iframefile" style="width: 100%;height: 55vh;"></iframe>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-primary btn-sm lebar" data-dismiss="modal"
                                @click="tutupmodal()"><i class="fas fa-window-close"></i> Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
        </center>
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12 wow fadeInDown">
                            <h5>Hello, Welcome {{ namauser }}</h5>
                            <input type="hidden" class="form-control form-control-sm" id="detail" name="detail"
                                placeholder="Detail">
                            <input type="hidden" class="form-control form-control-sm" id="Latitude" name="Latitude"
                                placeholder="Latitude">
                            <input type="hidden" class="form-control form-control-sm" id="Longitude" name="Longitude"
                                placeholder="Longitude">
                            <button type="button" class="btn btn-tool" id="kosong" @click="datakosong()"
                                style="display: none;"><i class="fas fa-minus text-white"></i></button>
                            <button type="button" class="btn btn-tool" id="modalku" @click="bukamodal()"
                                style="display: none;"><i class="fas fa-minus text-white"></i></button>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <iframe :src="sourcenya" class="col-sm-12 text-center" style="min-height: 95vh;"
                                frameborder="0" id="maps" scrolling="no" @load="loadpeta()"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert2';
import vSelect from 'vue-select';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            linknya: process.env.VUE_APP_URL_API,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            sourcenya: '',
        }
    },
    methods: {
        datakosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Layer Gagal Diunduh..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
        tutupmodal() {
            $('#costumModaldisclaimer').modal('hide');
        },
        bukamodal() {
            $('#costumModaldisclaimer').modal('show');
        },
        loadpeta() {
            this.halamanloading = true;
            const message = this.linknya + '|' + this.secretencData;
            const iframe = document.getElementById("maps");
            this.sourcenya = '';
            this.sourcenya = '/map.html';
            iframe.contentWindow.postMessage(message, "*");
            this.halamanloading = false;
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.halamanloading = true;
    }
}
</script>
<style></style>